"use client";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { m } from "framer-motion";
import { PageNotFoundIllustration } from "shared/ui/assets/illustrations";
import { MotionContainer, varBounce } from "shared/ui/components/animate";
import CompactLayout from "shared/ui/layouts/compact";
import { RouterLink } from "shared/ui/routes/components";

// ----------------------------------------------------------------------

export default function NotFoundView() {
  return <CompactLayout data-sentry-element="CompactLayout" data-sentry-component="NotFoundView" data-sentry-source-file="not-found-view.tsx">
      <MotionContainer data-sentry-element="MotionContainer" data-sentry-source-file="not-found-view.tsx">
        <m.div variants={varBounce().in} data-sentry-element="unknown" data-sentry-source-file="not-found-view.tsx">
          <Typography variant="h3" sx={{
          mb: 2
        }} data-sentry-element="Typography" data-sentry-source-file="not-found-view.tsx">
            Sorry, Page Not Found!
          </Typography>
        </m.div>

        <m.div variants={varBounce().in} data-sentry-element="unknown" data-sentry-source-file="not-found-view.tsx">
          <Typography sx={{
          color: "text.secondary"
        }} data-sentry-element="Typography" data-sentry-source-file="not-found-view.tsx">
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check your
            spelling.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in} data-sentry-element="unknown" data-sentry-source-file="not-found-view.tsx">
          <PageNotFoundIllustration sx={{
          height: 260,
          my: {
            xs: 5,
            sm: 10
          }
        }} data-sentry-element="PageNotFoundIllustration" data-sentry-source-file="not-found-view.tsx" />
        </m.div>

        <Button component={RouterLink} href="/" size="large" variant="contained" data-sentry-element="Button" data-sentry-source-file="not-found-view.tsx">
          Go to Home
        </Button>
      </MotionContainer>
    </CompactLayout>;
}